import request from "@/utils/httpRequest";

export default {
  list: function (params) {
    return request({
      url: "/front/member/cardRecharge/list",
      method: "get",
      params: params
    });
  },
  submit: function (cardNo) {
    return request({
      url: "/front/member/cardRecharge/submit",
      method: "get",
      params: { cardNo: cardNo },
    });
  },
  save: function (data) {
    return request({
      url: "/front/member/cardRecharge/save",
      method: "post",
      data: data,
    });
  },
};
