import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../layout/common/Layout.vue";
import UserCenterLayout from "../layout/userCenter";
import DocLayout from "../layout/doc/index.vue";
import TalkLayout from "../layout/talk/Layout.vue";
import DrawLayout from "../layout/draw/Layout.vue";
import WriteLayout from "../layout/write/Layout.vue";
import HomeLayout from "../layout/home/Layout.vue";
import VideoLayout from "../layout/video/Layout.vue";
import MusicLayout from "../layout/music/Layout.vue";
import HeaderLayout from "../layout/headerLayout/index.vue";
import accessHistoryService from "@/service/api/bbs/website/accessHistoryService";

// const originalPush = VueRouter.prototype.push;
// //修改原型对象中的push方法
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch((err) => err);
// };

Vue.use(VueRouter);

const routes = [
  {
    path: "/userCenter",
    name: "UserCenterLayout",
    component: UserCenterLayout,
    redirect: "/userCenter/home",
    children: [
      {
        path: "home",
        name: "Home",
        component: () =>
          import(
            /* webpackChunkName: "Home" */
            "@/views/userCenter/user/home/Home.vue"
          ),
      },
      {
        path: "user/member",
        name: "userMember",
        component: () =>
          import(
            /* webpackChunkName: "Home" */
            "@/views/userCenter/user/ai/MemberList.vue"
          ),
      },
      {
        path: "user/card",
        name: "userCard",
        component: () =>
          import(
            /* webpackChunkName: "Home" */
            "@/views/userCenter/user/ai/CardList.vue"
          ),
      },
      {
        path: "user/order",
        name: "userOrder",
        component: () =>
          import(
            /* webpackChunkName: "Home" */
            "@/views/userCenter/user/ai/OrderList.vue"
          ),
      },
      {
        path: "admin/home",
        name: "AdminHome",
        component: () =>
          import(
            /* webpackChunkName: "AdminHome" */
            "@/views/userCenter/admin/Home.vue"
          ),
      },
      {
        path: "admin/member",
        name: "adminMember",
        component: () =>
          import(
            /* webpackChunkName: "Home" */
            "@/views/userCenter/admin/ai/MemberList.vue"
          ),
      },
      {
        path: "admin/card",
        name: "adminCard",
        component: () =>
          import(
            /* webpackChunkName: "Home" */
            "@/views/userCenter/admin/ai/CardList.vue"
          ),
      },
      {
        path: "admin/costBill",
        name: "adminBill",
        component: () =>
          import(
            /* webpackChunkName: "Home" */
            "@/views/userCenter/admin/ai/CostBillList.vue"
          ),
      },
      {
        path: "admin/order",
        name: "adminOrder",
        component: () =>
          import(
            /* webpackChunkName: "Home" */
            "@/views/userCenter/admin/ai/OrderList.vue"
          ),
      },
      {
        path: "admin/agent",
        name: "adminAgent",
        component: () =>
          import(
            /* webpackChunkName: "Home" */
            "@/views/userCenter/admin/ai/AgentList.vue"
          ),
      },
      {
        path: "admin/audit/post",
        name: "AuditPost",
        component: () =>
          import(
            /* webpackChunkName: "AuditPost" */
            "@/views/userCenter/admin/AuditPost.vue"
          ),
      },
      {
        path: "admin/audit/message",
        name: "AuditMessage",
        component: () =>
          import(
            /* webpackChunkName: "AuditMessage" */
            "@/views/userCenter/admin/AuditComment.vue"
          ),
      },
      {
        path: "admin/today/post",
        name: "TodayPost",
        component: () =>
          import(
            /* webpackChunkName: "AuditPost" */
            "@/views/userCenter/admin/TodayPost.vue"
          ),
      },
      {
        path: "admin/today/message",
        name: "TodayMessage",
        component: () =>
          import(
            /* webpackChunkName: "AuditMessage" */
            "@/views/userCenter/admin/TodayComment.vue"
          ),
      },
      {
        path: "admin/auth/user/list",
        name: "UserList",
        component: () =>
          import(
            /* webpackChunkName: "UserList" */
            "@/views/userCenter/admin/user/UserList.vue"
          ),
      },
      {
        path: "admin/auth/access/list",
        name: "AccessHistoryList",
        component: () =>
          import(
            /* webpackChunkName: "AccessHistoryList" */
            "@/views/userCenter/admin/user/AccessHistoryList.vue"
          ),
      },
      {
        path: "admin/auth/cooperation/list",
        name: "CooperationList",
        component: () =>
          import(
            /* webpackChunkName: "CooperationList" */
            "@/views/userCenter/admin/user/CooperationList.vue"
          ),
      },
      {
        path: "admin/auth/login/list",
        name: "LoginHistoryList",
        component: () =>
          import(
            /* webpackChunkName: "LoginHistoryList" */
            "@/views/userCenter/admin/user/LoginHistoryList.vue"
          ),
      },
      {
        path: "myPost",
        name: "MyPost",
        component: () =>
          import(
            /* webpackChunkName: "MyPost" */ "@/views/userCenter/user/post/MyPost.vue"
          ),
      },
      {
        path: "myMessage",
        name: "MyMessage",
        component: () =>
          import(
            /* webpackChunkName: "MyMessage" */ "@/views/userCenter/user/MyMessage.vue"
          ),
      },
      {
        path: "myCollectedPost",
        name: "MyCollectedPost",
        component: () =>
          import(
            /* webpackChunkName: "MyCollectedPost" */ "@/views/userCenter/user/post/MyCollectedPost.vue"
          ),
      },

      {
        path: "userInfo",
        name: "UserInfo",
        component: () =>
          import(
            /* webpackChunkName: "UserInfo" */ "@/views/userCenter/user/UserInfo.vue"
          ),
      },
      {
        path: "*",
        component: () =>
          import(
            /* webpackChunkName: "index" */
            "@/views/404.vue"
          ),
      },
    ],
  },
  {
    path: "/doc",
    name: "docLayout",
    component: DocLayout,
    redirect: "/doc/detail/:rootId/:childId",
    children: [
      {
        path: "detail/:rootId/:childId",
        name: "docDetail",
        component: () =>
          import(
            /* webpackChunkName: "index" */
            "@/views/doc/detail/index.vue"
          ),
      },
      {
        path: "*",
        component: () =>
          import(
            /* webpackChunkName: "index" */
            "@/views/404.vue"
          ),
      },
    ],
  },
  {
    path: "/",
    name: "homeLayout",
    component: HomeLayout,
    redirect: "home",
    children: [
      {
        path: "/",
        redirect: "home",
      },
      {
        path: "home",
        name: "HomePage",
        component: () =>
          import(
            /* webpackChunkName: "HomePage" */
            "@/views/homePage/index.vue"
          ),
      },
    ],
  },
  {
    path: "/",
    name: "writeLayout",
    component: WriteLayout,
    redirect: "write",
    children: [
      {
        path: "write",
        name: "Write",
        component: () =>
          import(
            /* webpackChunkName: "Store" */
            "@/views/write/write.vue"
          ),
      },
      {
        path: "writeDetail/:id",
        name: "WriteDetail",
        component: () =>
          import(
            /* webpackChunkName: "Store" */
            "@/views/write/writeDetail.vue"
          ),
      },
      {
        path: "modify",
        name: "Modify",
        component: () =>
          import(
            /* webpackChunkName: "Store" */
            "@/views/write/modify.vue"
          ),
      },
    ],
  },
  {
    path: "/",
    name: "talkLayout",
    component: TalkLayout,
    redirect: "talk",
    children: [
      {
        path: "talk",
        name: "Talk",
        component: () =>
          import(
            /* webpackChunkName: "Store" */
            "@/views/talk/index.vue"
          ),
      },
      {
        path: "assistant",
        name: "Assistant",
        component: () =>
          import(
            /* webpackChunkName: "Store" */
            "@/views/assistant/index.vue"
          ),
      },
    ],
  },
  {
    path: "/",
    name: "drawLayout",
    component: DrawLayout,
    redirect: "Text2image",
    children: [
      {
        path: "/draw",
        name: "draw",
        component: () =>
          import(
            /* webpackChunkName: "draw" */
            "@/views/draw/draw.vue"
          ),
      },
      {
        path: "/baiduDraw",
        name: "baiduDraw",
        component: () =>
          import(
            /* webpackChunkName: "draw" */
            "@/views/draw/baiduDraw.vue"
          ),
      },
      {
        path: "/dallDraw",
        name: "dallDraw",
        component: () =>
          import(
            /* webpackChunkName: "draw" */
            "@/views/draw/dallDraw.vue"
          ),
      },
      {
        path: "/hgDallDraw",
        name: "hgDallDraw",
        component: () =>
          import(
            /* webpackChunkName: "draw" */
            "@/views/draw/hgDallDraw.vue"
          ),
      },
      {
        path: "/hgSdDraw",
        name: "hgSdDraw",
        component: () =>
          import(
            /* webpackChunkName: "draw" */
            "@/views/draw/hgSdDraw.vue"
          ),
      },
      {
        path: "/draw-history",
        name: "drawHistory",
        component: () =>
          import(
            /* webpackChunkName: "draw" */
            "@/views/draw/drawHistory.vue"
          ),
      },
      {
        path: "/sdDraw",
        name: "sdDraw",
        component: () =>
          import(
            /* webpackChunkName: "draw" */
            "@/views/draw/sdDraw.vue"
          ),
      },
      {
        path: "/txDraw",
        name: "txDraw",
        component: () =>
          import(
            /* webpackChunkName: "draw" */
            "@/views/draw/txDraw.vue"
          ),
      },
      {
        path: "/square",
        name: "square",
        component: () =>
          import(
            /* webpackChunkName: "draw" */
            "@/views/draw/square.vue"
          ),
      },
      {
        path: "/scrawl",
        name: "scrawl",
        component: () =>
          import(
            /* webpackChunkName: "draw" */
            "@/views/draw/scrawl.vue"
          ),
      },
    ],
  },

  {
    path: "/",
    name: "MusicLayout",
    component: MusicLayout,
    redirect: "musicSquare",
    children: [
      {
        path: "musicSquare",
        name: "musicSquare",
        component: () =>
          import(
            /* webpackChunkName: "music" */
            "@/views/music/musicSquare.vue"
          ),
      },
      {
        path: "music-creation",
        name: "MusicCreation",
        component: () =>
          import(
            /* webpackChunkName: "music" */
            "@/views/music/musicCreation.vue"
          ),
      },
      {
        path: "music-history",
        name: "MusicHistory",
        component: () =>
          import(
            /* webpackChunkName: "music" */
            "@/views/music/musicHistory.vue"
          ),
      },
    ],
  },
  {
    path: "/",
    name: "VideoLayout",
    component: VideoLayout,
    redirect: "aiVideo",
    children: [
      // {
      //   path: "aiVideo",
      //   name: "AiVideo",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "aiVideo" */
      //       "@/views/aiVideo/index.vue"
      //     ),
      // },
      {
        path: "img2video",
        name: "Img2video",
        component: () =>
          import(
            /* webpackChunkName: "aiVideo" */
            "@/views/aiVideo/img2video.vue"
          ),
      },
      {
        path: "text2video",
        name: "Text2video",
        component: () =>
          import(
            /* webpackChunkName: "aiVideo" */
            "@/views/aiVideo/text2video.vue"
          ),
      },

      {
        path: "img2videoMotion",
        name: "Img2videoMotion",
        component: () =>
          import(
            /* webpackChunkName: "aiVideo" */
            "@/views/aiVideo/img2videoMotion.vue"
          ),
      },

      {
        path: "/videoSquare",
        name: "videoSquare",
        component: () =>
          import(
            /* webpackChunkName: "aiVideo" */
            "@/views/aiVideo/square.vue"
          ),
      },

      {
        path: "/myVideo",
        name: "myVideo",
        component: () =>
          import(
            /* webpackChunkName: "aiVideo" */
            "@/views/aiVideo/myVideo.vue"
          ),
      },
    ],
  },
  {
    path: "/",
    name: "HeaderLayout",
    component: HeaderLayout,
    children: [
      {
        path: "/myOrder",
        name: "myOrder",
        component: () =>
          import(
            /* webpackChunkName: "AiTool" */
            "@/views/mine/MyOrders.vue"
          ),
      },
      {
        path: "/myBill",
        name: "myBill",
        component: () =>
          import(
            /* webpackChunkName: "AiTool" */
            "@/views/mine/MyBills.vue"
          ),
      },
      {
        path: "/account",
        name: "account",
        component: () =>
          import(
            /* webpackChunkName: "AiTool" */
            "@/views/mine/Account.vue"
          ),
      },
      {
        path: "/aiTool",
        name: "aiTool",
        component: () =>
          import(
            /* webpackChunkName: "AiTool" */
            "@/views/aiTool/toolList.vue"
          ),
      },
      {
        path: "/removeBackground",
        name: "removeBackground",
        component: () =>
          import(
            /* webpackChunkName: "AiTool" */
            "@/views/aiTool/removeBackground.vue"
          ),
      },
      {
        path: "/removeText",
        name: "removeText",
        component: () =>
          import(
            /* webpackChunkName: "AiTool" */
            "@/views/aiTool/removeText.vue"
          ),
      },
      // {
      //   path: "/clean",
      //   name: "clean",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "AiTool" */
      //       "@/views/aiTool/clean.vue"
      //     ),
      // },
      {
        path: "/cleanup",
        name: "cleanup",
        component: () =>
          import(
            /* webpackChunkName: "AiTool" */
            "@/views/aiTool/cleanup.vue"
          ),
      },
      {
        path: "/removeWatermark",
        name: "removeWatermark",
        component: () =>
          import(
            /* webpackChunkName: "AiTool" */
            "@/views/aiTool/removeWatermark.vue"
          ),
      },
      {
        path: "/replaceBackground",
        name: "replaceBackground",
        component: () =>
          import(
            /* webpackChunkName: "AiTool" */
            "@/views/aiTool/replaceBackground.vue"
          ),
      },
      {
        path: "/upscale",
        name: "upscale",
        component: () =>
          import(
            /* webpackChunkName: "AiTool" */
            "@/views/aiTool/upscale.vue"
          ),
      },
      {
        path: "/replaceSky",
        name: "replaceSky",
        component: () =>
          import(
            /* webpackChunkName: "AiTool" */
            "@/views/aiTool/replaceSky.vue"
          ),
      },
    ],
  },
  {
    path: "/",
    name: "vipLayout",
    component: Layout,
    children: [
      {
        path: "/vip",
        name: "vip",
        component: () =>
          import(
            /* webpackChunkName: "vip" */
            "@/views/vip/index.vue"
          ),
      },
    ],
  },
  {
    path: "/",
    name: "Layout",
    component: Layout,
    redirect: "home",
    children: [
      {
        path: "user/home/:id",
        name: "UFHome",
        component: () =>
          import(
            /* webpackChunkName: "UFHome" */
            "@/views/userCenter/front/Home.vue"
          ),
      },
      {
        path: "login",
        name: "Login",
        component: () =>
          import(/* webpackChunkName: "Login" */ "@/views/login/index.vue"),
      },

      {
        path: "getWxInfo",
        name: "getWxInfo",
        component: () =>
          import(/* webpackChunkName: "Login" */ "@/views/login/wxLogin.vue"),
      },
      {
        path: "ask",
        name: "Ask",
        redirect: "/ask/list",
        component: () =>
          import(
            /* webpackChunkName: "Ask" */
            "@/views/ask/index.vue"
          ),

        children: [
          {
            path: "list",
            name: "AskList",
            component: () =>
              import(
                /* webpackChunkName: "AskList" */
                "@/views/ask/list.vue"
              ),
          },
          {
            path: "detail/:id",
            name: "AskDetail",
            component: () =>
              import(
                /* webpackChunkName: "AskDetail" */
                "@/views/ask/detail/index.vue"
              ),
          },
          {
            path: "new",
            name: "NewPost",
            component: () =>
              import(
                /* webpackChunkName: "NewDataTrader" */
                "@/views/ask/new/index.vue"
              ),
          },
          {
            path: "new/detail/:id",
            name: "EditPost",
            component: () =>
              import(
                /* webpackChunkName: "NewDataTrader" */
                "@/views/ask/new/index.vue"
              ),
          },
        ],
      },

      {
        path: "document",
        name: "Doc",
        component: () =>
          import(
            /* webpackChunkName: "Doc" */
            "@/views/doc/index.vue"
          ),
      },
      {
        path: "*",
        component: () =>
          import(
            /* webpackChunkName: "index" */
            "@/views/404.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior: () => ({ y: 0 }),
});

router.beforeEach((to, from, next) => {
  if (
    sessionStorage.getItem("jp-access") !== "1" &&
    (from.name == null ||
      to.query.code != undefined ||
      to.query.from != undefined)
  ) {
    accessHistoryService.add({
      referUrl: document.referrer,
      ad: to.query.from,
      code: to.query.code,
    });

    sessionStorage.setItem("jp-access", "1");
  }
  next();
});

export default router;
