import { Message } from "element-ui";

//是否为外部链接
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}
// 通用提示信息组件
export function SelfElMessage(options) {
  Message({ duration: 1000, ...options });
}
// 跳转到用户中心平台
export function goToUserCenter(path) {
  window.open(`/#/userCenter/${path}Console`);
}
/**
 * 日期格式化
 * @param originDate
 * @param type time是yyyy-MM-dd HH:mm:ss格式，date是yyyy-MM-dd格式，默认是time
 * @returns
 */
export function dateFormatter(originDate, type) {
  if (typeof originDate === "string" && !Number(originDate)) {
    return "";
  }
  let date = new Date();
  if (originDate instanceof Date) {
    date = originDate;
  } else {
    date = new Date(Number(originDate));
  }

  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const strDate = date.getDate().toString().padStart(2, "0");
  if (type === "date") {
    return `${date.getFullYear()}-${month}-${strDate}`;
  } else if (type == "minutes") {
    return `${date.getFullYear()}-${month}-${strDate} ${date.getHours()}:${date
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
  } else {
    return `${date.getFullYear()}-${month}-${strDate} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  }
}
//文件下载
export const downloadFile = (url, fileName) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  SelfElMessage({ type: "success", message: "下载成功" });
};
//
export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};
// 驼峰转下划线
export function toLine(name) {
  if (name.indexOf(".") < 0) {
    return name.replace(/([A-Z])/g, "_$1").toLowerCase();
  } else {
    return name;
  }
}

/**
 * 表单对象赋值:
 * 对目标对象存在且源对象同样存在的属性，全部覆盖；
 * 目标对象不存在但是源对象存在的属性， 全部丢弃；
 * 目标对象存在但是源对象不存在的属性，如果是字符串赋值为空串，其余类型赋值为undefined
 */
export function recover(target, source) {
  if (target === undefined || target === null) {
    throw new TypeError("Cannot convert first argument to object");
  }
  var to = Object(target);
  if (source === undefined || source === null) {
    return to;
  }
  var keysArray = Object.keys(Object(target));
  for (
    var nextIndex = 0, len = keysArray.length;
    nextIndex < len;
    nextIndex++
  ) {
    var nextKey = keysArray[nextIndex];
    var desc = Object.getOwnPropertyDescriptor(target, nextKey);
    if (desc !== undefined && desc.enumerable) {
      // eslint-disable-next-line no-prototype-builtins
      if (to.hasOwnProperty(nextKey)) {
        if (to[nextKey] instanceof Array) {
          to[nextKey] = source[nextKey];
        } else if (to[nextKey] instanceof Object) {
          recover(to[nextKey], source[nextKey]);
        } else if (source[nextKey] !== undefined) {
          to[nextKey] = source[nextKey];
        } else if (typeof to[nextKey] === "string") {
          to[nextKey] = "";
        } else {
          to[nextKey] = undefined;
        }
      }
    }
  }
  return to;
}

/**
 * 滚动条滚到顶部
 * @returns
 */

export function scrollToTop() {
  document.body.scrollTop = document.documentElement.scrollTop = 0;
}

export function uuid() {
  if (localStorage.getItem("uuid")) {
    return localStorage.getItem("uuid");
  } else {
    const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
    localStorage.setItem("uuid", uuid);
    return uuid;
  }
}
export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

