<template>
  <div class="header">
    <div class="header-inner">
      <div class="head-left-logo fcc" @click="linkToHome">
        <!-- <img
          height="30"
          src="@/assets/image/homePage/logo.png"
          alt="logo"
          @click="linkToHome"
          class="logo"
        /> -->
        <Logo class="logo"></Logo>
        <div class="title">AI灵感坊</div>
      </div>

      <div class="app-router-link">
        <el-menu
          v-for="(item, i) in menuData"
          router
          :key="i"
          :default-active="activeIndex"
          class="el-menu-app"
          mode="horizontal"
          @select="handleMenuSelect"
        >
          <el-menu-item @click="scrollTop()" :index="item.linkTo">
            <router-link tag="li" :to="item.linkTo">
              <span>{{ item.name }}</span>
            </router-link>
          </el-menu-item>
        </el-menu>
      </div>

      <div class="app-head-right">
        <div class="action-row">
          <div class="icon-function" @click="showCardDialog">
            <span role="img" class="anticon icon card-icon">
              <card style="font-size: 1.5em"></card>
            </span>
            <div>卡密充值</div>
            <span class="dot"></span>
          </div>

          <div class="icon-function">
            <el-popover placement="bottom" width="230" trigger="hover">
              <div class="gzh">
                <div>微信扫码关注公众号</div>
                <el-image
                  style="height: 130px; margin: 10px 0"
                  :src="$store.state.login.gzhSetting.customerServiceUrl"
                ></el-image>
                <div>通过手机使用AI功能</div>
              </div>
              <div slot="reference">
                <span role="img" class="anticon icon card-icon">
                  <gzh style="font-size: 1.5em"></gzh>
                </span>
                <div>公众号</div>
              </div>
            </el-popover>
          </div>
          <!---->

          <div class="icon-function">
            <el-popover placement="bottom" width="200" trigger="hover">
              <div class="gzh">
                <el-image
                  style="height: 130px"
                  :src="$store.state.login.gzhSetting.gzhUrl"
                ></el-image>
                <div>微信扫码咨询客服</div>
              </div>
              <div slot="reference">
                <span role="img" class="anticon icon card-icon">
                  <kefu style="font-size: 1.5em"></kefu>
                </span>
                <div>客服</div>
              </div>
            </el-popover>
          </div>
        </div>
        <div class="login-text" v-if="!currentUser || !currentUser.id">
          <el-button @click="$refs.loginPopup.init()" type="text"
            >登录/注册</el-button
          >
        </div>
        <div v-else class="already_login">
          <el-dropdown placement="bottom">
            <span class="el-dropdown-link">
              <el-avatar
                :size="28"
                :src="currentUser.avatarUrl"
                style="position: relative; top: 7px; right: 5px"
              ></el-avatar>
              <span>
                {{ currentUser.nickname }}
                <span
                  :class="[
                    'vip',
                    currentUser && currentUser.isExpired === '1'
                      ? 'expired'
                      : '',
                  ]"
                  v-if="currentUser && currentUser.isVip === '1'"
                >
                  VIP
                </span>
                <span class="point">
                  <i class="el-icon-coin"> </i>
                  {{ currentUser.score + currentUser.score2 }}</span
                >
              </span>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <div class="u_info">
                  <div class="u_info_top">
                    <div class="u_info_top_name">
                      {{ currentUser.nickname }}
                      <div class="u_info_marker">
                        <div class="login_out" @click="loginOut">退出登录</div>
                      </div>
                    </div>
                  </div>
                  <VipCard v-if="currentUser && currentUser.isVip !== '1'" />
                  <div class="u_info_content">
                    <ul class="menu">
                      <li class="menu_item" @click="$router.push('/myOrder')">
                        <div class="menu_item_label">
                          <i class="el-icon-s-order icon-color"></i>
                          <span>充值记录</span>
                        </div>
                      </li>
                      <li class="menu_item" @click="$router.push('/myBill')">
                        <div class="menu_item_label">
                          <i class="el-icon-shopping-cart-2 icon-color"></i>
                          <span>消费记录</span>
                        </div>
                      </li>
                      <li class="menu_item" @click="$router.push('/account')">
                        <div class="menu_item_label">
                          <i class="el-icon-setting icon-color"></i>
                          <span>账号信息</span>
                        </div>
                      </li>
                      <li
                        v-if="currentUser.role !== 'user'"
                        class="menu_item"
                        @click="go('home', '我的主页')"
                      >
                        <div class="menu_item_label">
                          <i class="el-icon-s-home icon-color"></i>
                          <span>我的主页</span>
                        </div>
                      </li>
                    </ul>
                    <!-- <div class="footer">
                      <div class="btn" @click="goToConsole('/buyer')">
                        去使用方控制台
                      </div>
                    </div> -->
                  </div>
                </div>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </div>
    <LoginPopup ref="loginPopup" />
    <CardDialog ref="cardDialog"></CardDialog>
  </div>
</template>

<script>
import { SelfElMessage, goToUserCenter } from "@/utils/utils";
import { getToken } from "@/service/token";
import loginService from "@/service/api/loginService";
import Logo from "@/components/icon/Logo.vue";
import card from "@/components/icon/sidebar/card.vue";
import gzh from "@/components/icon/sidebar/gzh.vue";
import kefu from "@/components/icon/sidebar/kefu.vue";
import VipCard from "../VipCard.vue";
import CardDialog from "../../components/card/CardDialog.vue";
export default {
  name: "Header",
  props: {
    msg: String,
  },
  components: { Logo, VipCard, card, gzh, kefu, CardDialog },
  data() {
    return {
      menuData: [
        { name: "首页", linkTo: "/home" },
        { name: "AI会话", linkTo: "/talk" },
        { name: "AI写作", linkTo: "/write" },
        { name: "AI绘画", linkTo: "/square" },
        { name: "AI音乐", linkTo: "/musicSquare" },
        { name: "AI视频", linkTo: "/videoSquare" },
        { name: "AI工具", linkTo: "/aiTool" },
        // { name: "交流", linkTo: "/ask" },
        { name: "会员中心", linkTo: "/vip" },
      ],
      activeIndex: "home",
      activeName: "user",
      flag: true,
    };
  },
  mounted() {
    this.init();
    const that = this;
    window.addEventListener(
      "unhandledrejection",
      function browserRejectionHandler(event) {
        console.log("unhandledrejection");
        if ([401, 408].includes(event.reason?.response?.status)) {
          that.$refs.loginPopup.init();
        }
      }
    );
  },
  methods: {
    async init() {
      if (getToken()) {
        try {
          this.$store.dispatch("getUserInfo").then(() => {
            console.log("login success");
          });
        } catch (e) {
          console.log(e);
        }
      }
    },
    showCardDialog() {
      this.$refs.cardDialog.show();
    },
    handleMenuSelect(key) {
      this.activeIndex = key;
    },
    scrollTop() {
      window.scrollTo(0, 0);
    },
    gotoAttestation() {
      if (!this.flag) {
        return;
      }
      this.flag = false;
    },
    loginOut() {
      this.$confirm("确定要退出登录吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          loginService.logout().then(() => {
            this.$store.dispatch("loginOut");
            this.$router.push({ path: "/home" });
            SelfElMessage({ type: "success", message: "退出登录成功" });
          });
        })
        .catch(() => {
          console.log("error!!");
        });
    },
    openUserCenter(path) {
      window.open(`/#/userCenter/${path}`);
    },
    go(type, menu) {
      const pre = `/#/userCenter/${type}`;
      let route = "";
      if (type == "buyer") {
        switch (menu) {
          case "订单管理":
            route = "/order/common";
            break;
          case "已购服务":
            route = "/purchased/myAPI";
            break;
        }
      } else if (type == "seller") {
        switch (menu) {
          case "订单管理":
            route = "/order/common";
            break;
        }
      }
      const url = pre + route;
      window.open(url);
    },
    goToConsole(path) {
      const userId = this.userDetail && this.userDetail.userId;
      goToUserCenter(path, userId);
    },
    linkToHome() {
      this.$router.push({ path: "/home" });
    },
    goDataRegister() {
      window.open("/#/userCenter/seller/data/dataRegister");
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters.getUser;
    },
    currentToken() {
      return getToken();
    },
    level() {
      const userInfo = this.$store.state.login.userInfo;
      let result = null;
      if (userInfo && userInfo.scoreLevel) {
        const num = userInfo.scoreLevel.replace(/\D/g, "");
        result = Number(num);
      }

      return result;
    },
    userDetail() {
      return this.$store.getters.getUserMessageDetail;
    },
    ShowDataAccession() {
      return this.$store.getters.getUserMessageDetail.sellerCertification
        ? true
        : false;
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import "~@/assets/css/default.less";
.gzh {
  display: flex;
  flex-direction: column; /* 确保元素垂直排列 */
  justify-content: center; /* 垂直居中 */
  align-items: center; /* 水平居中（对于一列元素，这个设置可能不是必需的，但如果你想确保水平方向也居中，可以保留） */
}
@downMenuTopBgColor: #f5f7f9;
.header {
  width: 100%;
  position: fixed;
  z-index: 100;
  background-color: #fff;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  .point {
    color: #ff5500 !important;
  }

  .vip {
    color: #fff;
    margin-left: 5px;
    padding-left: 5px;
    margin-right: 5px;
    background-image: linear-gradient(90deg, #fe8800 0%, #ff5500 100%);
  }
  .expired {
    color: #fff;
    background: #999;
  }

  .header-inner {
    .containerWidth;
    height: 50px;
    overflow: hidden;
  }
  .head-left-logo {
    float: left;
    line-height: 50px;
    height: 50px;
    margin-right: 100px;
    .logo {
      height: 25px;
      color: #8e66ff;
      font-weight: 700;
    }
    .title {
      cursor: pointer;
      font-size: 20px;
      // .main-active;
      font-weight: 600;
      // font-style: italic;
      width: 100px;
    }
  }
  .app-router-link {
    float: left;
    height: 50px;
    ::v-deep .el-menu--horizontal {
      border-bottom: none;
      display: inline-block;
      .el-menu-item {
        height: 50px;
        line-height: 50px;
        transition: none;
        font-size: 16px;
        padding: 0 25px;
        span {
          color: rgba(0, 0, 0, 0.85);
          line-height: 50px;
          font-size: 16px;
          &:hover {
            opacity: 1;
            .main-active;
          }
        }
        .router-link-active span {
          // color: #0089ff;
          opacity: 1;
          // font-weight: 600;
          .main-active;
        }
      }
      .el-menu-item.is-active {
        border-bottom: none;
      }
    }
  }

  .app-head-right {
    // margin-top: 24px;
    float: right;
    height: 100%;
    display: flex;
    align-items: center;
    float: right;
    .action-row {
      padding-right: 10px;
      row-gap: 0px;
      display: flex;
      line-height: 50px;
      .icon-function {
        text-align: center;
        line-height: 18px;
        color: #000000a6;
        font-size: 12px;
        cursor: pointer;
        border-radius: 5px;
        position: relative;
        width: 60px;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:hover {
          background-color: #f5f5f5;
          color: #7a46fc;
        }
        .anticon {
          display: inline-block;
          color: inherit;
          font-style: normal;
          line-height: 0;
          text-align: center;
          text-transform: none;
          vertical-align: -0.125em;
          text-rendering: optimizeLegibility;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          svg {
            display: inline-block;
          }
        }
        .dot[data-v-70c871f4] {
          position: absolute;
          width: 6px;
          height: 6px;
          background-color: #ff4d4f;
          border-radius: 50%;
          top: 6px;
          right: 6px;
        }
      }
    }
    .already_login {
      line-height: 50px;
    }
    .login-text {
      background: #7a46fc;
      border-color: #7a46fc;
      white-space: nowrap;
      text-align: center;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
      .el-button {
        padding: 5px 7px;
        font-size: 14px;
        color: #fff !important;
        display: inline-block;
        font-weight: 400;
      }
    }
    li {
      display: inline;
      cursor: pointer;
      color: #111111;
      font-weight: 400;
      font-size: 14px;
    }
  }
}
.el-dropdown-menu {
  padding: 0 !important;
}
.el-popper.el-dropdown-menu {
  ::v-deep .popper__arrow::after {
    border-bottom-color: @downMenuTopBgColor;
  }
}

::v-deep .el-tabs--border-card {
  border: none;
  > .el-tabs__content {
    padding: 0 !important;
  }
}

.el-badge__content--primary {
  background-color: @mainColor !important;
}
.already_login {
  ::v-deep .el-dropdown-link {
    cursor: pointer;
  }
  .level {
    position: absolute;
    left: 14px;
    bottom: -6px;
  }
  .name {
    margin-left: 8px;
  }
}
.u_info {
  min-width: 200px;

  .icon-color {
    font-size: 16px;
    color: #777 !important;
  }
  ::v-deep .el-tabs__nav {
    width: 100%;
    display: flex;
    .el-tabs__item {
      flex: 1;
      text-align: center;
      &.is-active {
        color: @mainColor !important;
      }
      &:hover {
        color: @mainColor !important;
      }
    }
  }
  &_top {
    padding: 20px 20px 10px 20px;
    background: @downMenuTopBgColor;
    background: #fff
      linear-gradient(180deg, #ede7fe 0%, rgba(255, 255, 255, 0.5) 100%);
    &_name {
      cursor: pointer;
      font-size: 16px;
      color: #111111;
      display: flex;
      justify-content: space-between;
    }
    .u_info_marker {
      padding-top: 10px;
      align-items: center;
      font-size: 12px;
      .marker {
        padding: 4px;
        color: #fff;
        border-radius: 4px;
        cursor: pointer;
      }
    }
    .login_out {
      font-size: 12px;
      opacity: 0.8;
      cursor: pointer;
      color: #666;
      line-height: 0.8;
      padding-bottom: 4px;
      border-bottom: 1px solid #666;
    }
  }
  &_content {
    ::v-deep .el-tabs__nav {
      transition: none;
    }
    ::v-deep .el-tabs__item {
      font-weight: 400;
    }
    ::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item {
      border: 1px solid #e5e7e9;
      background-color: #fff;
    }
  }
  ::v-deep .menu {
    padding: 15px;
    min-height: 120px;
    &_item {
      padding: 10px 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &_label {
        font-size: 14px;
        span {
          display: inline-block;
          padding-left: 10px;
        }
      }
    }
  }
  .footer {
    border-top: 1px solid #dcdfe6;
    padding: 10px;
    .btn {
      border: 1px solid #3873ec;
      padding: 10px;
      width: 77.77%;
      margin: auto;
      background: #fff;
      border-radius: 20px;
      text-align: center;
      color: #3873ec;
      font-weight: 400;
      cursor: pointer;
      font-size: 14px;
      &:hover {
        background: #3873ec;
        color: #fff;
      }
    }
  }
}
</style>
