<template>
  <div>
    <el-dialog
      title="卡密兑换"
      append-to-body
      :visible.sync="visible"
      width="30%"
      left
    >
      <el-input
        type="text"
        maxlength="100"
        v-model="content"
        placeholder="你输入你的卡密"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button round type="primary" @click="submit">立即兑换</el-button>
      </span>
    </el-dialog>
    <LoginPopup ref="loginPopup" />
  </div>
</template>
<script>
import cardRechargeService from "@/service/api/cardRecharge/cardRechargeService.js";
import { getToken } from "@/service/token";
export default {
  data() {
    return {
      content: "",
      visible: false,
    };
  },
  computed: {
    reversedHistoryList() {
      // 创建一个反转后的数组的副本，而不是修改原始数组
      return this.historyList.slice().reverse();
    },
  },
  created() {},
  methods: {
    show() {
      if (!getToken()) {
        this.$refs.loginPopup.init();
        return;
      }
      this.visible = true;
    },
    submit() {
      if (!this.content) {
        this.$message.error("卡号不能为空!");
        return;
      }
      cardRechargeService.submit(this.content).then(() => {
        this.$message.success("兑换成功!");
        this.$store.dispatch("getUserInfo");
      });
    },
  },
};
</script>
<style lang="less" scoped></style>
