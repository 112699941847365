<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import webNotifyService from "@/service/api/bbs/website/webNotifyService.js";
import apiSettingService from "@/service/api/apiSettingService.js";
import configService from "@/service/api/config/configService";
export default {
  name: "app",
  created() {
    webNotifyService.getNotify().then((data) => {
      if (data.id && sessionStorage.getItem("notify_id") != data.id) {
        this.$notify({
          title: data.title,
          dangerouslyUseHTMLString: true,
          message: data.content,
          duration: 30000,
        });
        sessionStorage.setItem("notify_id", data.id);
      }
    });
    apiSettingService.list().then((data) => {
      this.$store.dispatch("setApiSetting", data);
    });
    configService.getConfig().then((data) => {
      this.$store.dispatch("setGzhSetting", data);
      // this.customerServiceUrl = data.customerServiceUrl;
      // this.gzhUrl = data.gzhUrl;
    });
  },
};
</script>

<style lang="less">
@import url("./assets/css/mixin.less");
html,
body,
#app {
  height: 100%;
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  font-feature-settings: "tnum";
}
</style>
