<template>
  <div class="layout">
    <Header />
    <div class="main">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "./Header.vue";
import Footer from "../home/Footer.vue";

export default {
  name: "Layout",
  components: {
    Header,
    Footer,
  },
  props: {
    msg: String,
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.layout {
  .main {
    padding: 50px 0 0;
  }
}
</style>
