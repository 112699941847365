<template>
  <div class="footer">
    <div class="app-foot-inner">
      <div class="app-foot-content">
        <div class="divider-white"></div>
        <div class="divider-black"></div>

        <div class="copy-right">
          <span class="beian" @click="goLink">苏ICP备2023013800号-1</span>
          版权所有 @2024 awzs.work. All Rights Reserved
        </div>
        <Agreement
          :modelValue="dialogVisible"
          @setDialogVisible="setDialogVisible"
        />
      </div>
    </div>
  </div>
</template>

<script>
const Agreement = () => import("@/views/agreement/index.vue");
export default {
  name: "Footer",
  props: {
    msg: String,
  },
  components: {
    Agreement,
  },
  created() {
    this.init();
  },
  data() {
    return {
      result: {
        hotPostList: [],
        topUserList: [],
        link: [],
      },
      showDataCustom: false,
      dialogVisible: false,
    };
  },
  methods: {
    async init() {},
    footLink: function (val) {
      if (!val) {
        return;
      }
      if (val.indexOf("http") == 0) {
        window.open(val);
      } else {
        this.$router.push(val);
      }
    },
    closeDataCustom() {
      this.showDataCustom = false;
    },
    setDialogVisible(flag) {
      this.dialogVisible = flag;
    },
    openDialog(val) {
      if (val == "data-custom") {
        this.showDataCustom = true;
      } else if (val == "user_agreement") {
        this.setDialogVisible(true);
      }
    },
    goLink: function () {
      window.open("https://beian.miit.gov.cn/");
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import "~@/assets/css/default.less";
.footer {
  overflow: hidden;
  background: #2d353d;
  height: 70px;

  .app-foot-inner {
    .containerWidth;
    padding: 0 100px;
    .app-foot-content {
      width: 100%;
      // margin-top: 25px;
      .app-link-to {
        display: flex;
        padding: 15px 0;
        font-weight: 400;
        .foot-content-title {
          font-size: 14px;
          color: #ffffff;
          font-weight: 600;
          float: left;
          margin-right: 40px;
        }
        .foot-content-link {
          font-size: 14px;
          opacity: 0.4;
          color: #ffffff;
          margin-right: 30px;
          cursor: pointer;
        }
        .foot-content-about {
          font-size: 14px;
          color: #ffffff;
          margin-right: 30px;
          cursor: pointer;
        }
        .foot-content-info {
          margin-right: 30px;
          font-size: 14px;
          color: #ffffff;
        }
      }
      .app-main-content {
        position: relative;
        .link-items {
          display: block;
          .app-link-to;
        }
        .app-qrCode {
          position: absolute;
          right: 10px;
          text-align: center;
          top: 12px;
          img {
            width: 100px;
            display: block;
            margin-bottom: 8px;
          }
          label {
            font-size: 14px;
            color: #fff;
          }
        }
      }
      .divider-white {
        height: 1px;
        width: 100%;
        opacity: 0.1;
        background: #ffffff;
      }
      .divider-black {
        height: 1px;
        width: 100%;
        opacity: 0.2;
        background: #000000;
      }
      .about-us {
        // margin: 15px 0;
      }
      .copy-right {
        text-align: center;
        width: 100%;
        height: 40px;
        line-height: 40px;
        margin-top: 15px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        .beian {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
