<template>
  <div class="sidebar-wrapper">
    <el-scrollbar>
      <el-menu v-if="role === 'user'" :default-active="activeMenu">
        <router-link to="/userCenter/home">
          <el-menu-item index="/userCenter/home">
            <i class="el-icon-s-home"></i>
            <span slot="title">我的主页</span>
          </el-menu-item>
        </router-link>
        <!-- <router-link to="/userCenter/userInfo">
          <el-menu-item index="/userCenter/userInfo">
            <i class="el-icon-user-solid"></i>
            <span slot="title">账号信息</span>
          </el-menu-item>
        </router-link> -->
        <router-link to="/userCenter/user/member">
          <el-menu-item index="/userCenter/user/member">
            <i class="el-icon-user-solid"></i>
            <span slot="title">会员管理</span>
          </el-menu-item>
        </router-link>
        <router-link to="/userCenter/user/card">
          <el-menu-item index="/userCenter/user/card">
            <i class="el-icon-bank-card"></i>
            <span slot="title">卡密管理</span>
          </el-menu-item>
        </router-link>
        <router-link to="/userCenter/user/order">
          <el-menu-item index="/userCenter/user/order">
            <i class="el-icon-s-order"></i>
            <span slot="title">订单管理</span>
          </el-menu-item>
        </router-link>
      </el-menu>

      <el-menu v-else :default-active="activeMenu">
        <router-link to="/userCenter/admin/home">
          <el-menu-item index="/userCenter/admin/home">
            <i class="el-icon-s-home"></i>
            <span slot="title">管理员控制台</span>
          </el-menu-item>
        </router-link>
        <router-link to="/userCenter/admin/agent">
          <el-menu-item index="/userCenter/admin/agent">
            <i class="el-icon-user-solid"></i>
            <span slot="title">代理管理</span>
          </el-menu-item>
        </router-link>
        <router-link to="/userCenter/admin/member">
          <el-menu-item index="/userCenter/admin/member">
            <i class="el-icon-user-solid"></i>
            <span slot="title">会员管理</span>
          </el-menu-item>
        </router-link>
        <router-link to="/userCenter/admin/card">
          <el-menu-item index="/userCenter/admin/card">
            <i class="el-icon-bank-card"></i>
            <span slot="title">卡密管理</span>
          </el-menu-item>
        </router-link>
        <router-link to="/userCenter/admin/order">
          <el-menu-item index="/userCenter/admin/order">
            <i class="el-icon-s-order"></i>
            <span slot="title">订单管理</span>
          </el-menu-item>
        </router-link>
        <router-link to="/userCenter/admin/costBill">
          <el-menu-item index="/userCenter/admin/costBill">
            <i class="el-icon-s-order"></i>
            <span slot="title">支出管理</span>
          </el-menu-item>
        </router-link>
        <!-- <el-submenu index="2">
          <template slot="title">
            <i class="el-icon-s-claim"></i>
            <span>待审核帖子</span>
          </template>
          <el-menu-item-group>
            <router-link to="/userCenter/admin/today/post">
              <el-menu-item index="/userCenter/admin/today/post"
                >今日新增帖子</el-menu-item
              >
            </router-link>
            <router-link to="/userCenter/admin/today/message">
              <el-menu-item index="/userCenter/admin/today/message"
                >今日新增评论</el-menu-item
              >
            </router-link>
            <router-link to="/userCenter/admin/audit/post">
              <el-menu-item index="/userCenter/admin/audit/post"
                >待审核帖子</el-menu-item
              >
            </router-link>
            <router-link to="/userCenter/admin/audit/message">
              <el-menu-item index="/userCenter/admin/audit/message"
                >待审核评论</el-menu-item
              >
            </router-link>
          </el-menu-item-group>
        </el-submenu> -->
        <el-submenu index="3">
          <template slot="title">
            <i class="el-icon-s-custom"></i>
            <span>网站管理</span>
          </template>
          <el-menu-item-group>
            <router-link to="/userCenter/admin/auth/login/list">
              <el-menu-item index="/userCenter/admin/auth/login/list"
                >登录记录</el-menu-item
              >
            </router-link>
            <router-link to="/userCenter/admin/auth/access/list">
              <el-menu-item index="/userCenter/admin/auth/access/list"
                >访问记录</el-menu-item
              >
            </router-link>
            <router-link to="/userCenter/admin/auth/cooperation/list">
              <el-menu-item index="/userCenter/admin/auth/cooperation/list"
                >申请合作</el-menu-item
              >
            </router-link>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  name: "sidebar",
  props: ["role"],
  computed: {
    activeMenu() {
      const route = this.$route;
      const { path } = route;
      return path;
    },
  },
};
</script>

<style lang="less">
.el-scrollbar {
  overflow: hidden;
  position: relative;
  height: 100%;
}
.sidebar-wrapper {
  width: 240px;
  min-height: calc(100vh - 61px);
  .el-menu,
  .el-scrollbar__view {
    height: 100% !important;
    background-color: #f5f7f9;
  }
  .el-submenu__title:hover {
    background-color: #e6e9eb;
  }
  .el-submenu {
    text-align: left;
  }
  .el-menu-item {
    text-align: left;
    &:focus {
      background-color: rgba(0, 0, 0, 0);
    }
    &:hover {
      background-color: #e6e9eb;
    }
  }
}
.el-submenu__title i {
  color: #909399 !important;
}
</style>
