<template>
  <div class="layout">
    <Header />
    <div class="main">
      <div class="sidebar">
        <Sidebar :menu="menu" />
      </div>
      <router-view />
    </div>
    <!-- <Footer /> -->
  </div>
</template>

<script>
import Header from "@/layout/common/Header.vue";
// import Header from "./Header.vue";
// import Footer from "./Footer.vue";
import Sidebar from "@/layout/common/Sidebar";
export default {
  name: "Layout",
  components: {
    Header,
    Sidebar,
    // Footer,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      menu: [
        {
          name: "创意广场",
          path: "/videoSquare",
          icon: "videoSquare",
        },
        {
          name: "图生视频",
          path: "/img2video",
          icon: "aiVideo",
        },
        {
          name: "文生视频",
          path: "/text2video",
          icon: "aiVideo1",
        },
        {
          name: "照片跳舞",
          path: "/img2videoMotion",
          icon: "aiVideo2",
        },
        {
          name: "我的视频",
          path: "/myVideo",
          icon: "myVideo",
        },
      ],
    };
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.layout {
  height: 100%;
  .main {
    padding-top: 50px;
    height: 100%;
    display: flex;
  }
}
</style>
