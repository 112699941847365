import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/css/reset.less";
import SvgIcon from "@/components/SvgIcon.vue";
import "@/icons/index";
// import { NetworkError } from "@/service/api";
// import { SelfElMessage } from "@/utils/utils";
import dictUtils from "@/utils/dictUtils";
import {recover} from "@/utils/utils.js";
import "@/assets/css/reset.less";
// markdown
import mavonEditor from "mavon-editor";
import "mavon-editor/dist/css/index.css";
import LoginPopup from "@/components/login/LoginPopup";
Vue.use(mavonEditor);
Vue.config.productionTip = false;
Vue.prototype.$dictUtils = dictUtils;
Vue.prototype.recover = recover;
dictUtils.refreshDictList();
Vue.use(ElementUI);
Vue.component("svg-icon", SvgIcon);

import "echarts";
import ECharts from "vue-echarts";
Vue.component("VChart", ECharts);
Vue.component("LoginPopup", LoginPopup);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
// window.addEventListener(
//   "unhandledrejection",
//   function browserRejectionHandler(event) {
//     if (event.reason instanceof NetworkError) {
//       event && event.preventDefault();
//       SelfElMessage({ type: "error", message: event.reason.message });
//       if (event.reason.response?.code === 1000) {
//         sessionStorage.setItem(
//           "beforeRoute",
//           window.location.hash.split("#")[1]
//         );
//         store.dispatch("loginOut");
//         router.push("/login");
//       }
//     }
//   }
// );
