<template>
  <div class="layout">
    <Header />
    <div class="main">
      <router-view />
    </div>
    <!-- <Footer /> -->
  </div>
</template>

<script>
import Header from "@/layout/common/Header.vue";
// import Header from "./Header.vue";
// import Footer from "./Footer.vue";
export default {
  name: "Layout",
  components: {
    Header,
    // Footer,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      menu: [
        {
          name: "AI写作",
          path: "/write",
          icon: "talk",
        },
        {
          name: "伪原创",
          path: "/modify",
          icon: "assistant",
        },
      ],
    };
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.layout {
  height: 100%;
  .main {
    padding-top: 50px;
    height: 100%;
    display: flex;
  }
}
</style>
