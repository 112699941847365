<template>
  <div class="vip-box">
    <div class="left">
      <div class="top">
        <img src="@/assets/image/vip/vip_icon.png" />
        开通VIP
      </div>
      <div class="desc">会员中心</div>
    </div>
    <div class="right" @click="$router.push('/vip')">开通</div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.vip-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px;
  background: url("~@/assets/image/vip/vip_card_bg1.png") no-repeat 0 0;
  background-size: 100% 100%;
  font-size: 14px;
  padding: 10px 20px;
  .left {
    img {
      width: 20px;
    }
    .desc {
      font-size: 12px;
      color: #999;
    }
  }
  .right {
    background: linear-gradient(154deg, #ff9643 0%, #ff5d20 100%);
    padding: 3px 10px;
    color: #fff;
    border-radius: 20px;
    font-size: 12px;
    cursor: pointer;
  }
}
</style>
