import request from "@/utils/httpRequest";

export default {
  getConfig: function () {
    return request({
      url: "/front/config/getConfig",
      method: "get",
    });
  },
};
