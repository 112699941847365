<template>
  <div class="layout">
    <Header />
    <div class="main">
      <div class="sidebar">
        <Sidebar :menu="menu" />
      </div>
      <router-view />
    </div>
    <!-- <Footer /> -->
  </div>
</template>

<script>
import Header from "@/layout/common/Header.vue";
// import Header from "./Header.vue";
// import Footer from "./Footer.vue";
import Sidebar from "@/layout/common/Sidebar";
export default {
  name: "Layout",
  components: {
    Header,
    Sidebar,
    // Footer,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      menu: [
        {
          name: "绘图广场",
          path: "/square",
          icon: "square",
        },
        {
          name: "MJ绘图",
          path: "/draw",
          icon: "draw",
        },
        // {
        //   name: "TX绘图",
        //   path: "/txDraw",
        //   icon: "tx",
        // },

        {
          name: "Dall绘图",
          path: "/dallDraw",
          icon: "dall",
        },
        {
          name: "SD绘图",
          path: "/sdDraw",
          icon: "sd",
        },

        {
          name: "XD绘图",
          path: "/hgSdDraw",
          icon: "xd",
          tip: this.$store.state.login.apiSetting["017"] == 0 ? "免费" : "",
        },
        {
          name: "MX绘图",
          path: "/hgDallDraw",
          icon: "mx",
          tip: this.$store.state.login.apiSetting["016"] == 0 ? "免费" : "",
        },
        {
          name: "作图历史",
          path: "/draw-history",
          icon: "history",
        },
      ],
    };
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.layout {
  height: 100%;
  .main {
    padding-top: 50px;
    height: 100%;
    display: flex;
  }
}
</style>
