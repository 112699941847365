<template>
  <div class="layout_sidebar">
    <div
      :class="['submenu', defaultActive === item.path ? 'active' : '']"
      v-for="(item, index) in menu"
      :key="index"
      @click="change(item)"
    >
      <div class="icon_box">
        <el-badge :value="item.tip" class="item">
          <component :is="item.icon" />
        </el-badge>
      </div>
      <div class="name">{{ item.name }}</div>
    </div>
  </div>
</template>
<script>
import comps from "../../components/icon/sidebar";

export default {
  components: { ...comps },
  props: {
    menu: Array,
  },
  data() {
    return {
      list: [
        {
          name: "AI会话",
          path: "/talk",
          icon: "talk",
        },
        {
          name: "文案写作",
          path: "/write",
          icon: "write",
        },
        {
          name: "MJ绘图",
          path: "/draw",
          icon: "draw",
        },
        {
          name: "腾讯绘图",
          path: "/txDraw",
          icon: "tx",
        },
        {
          name: "Dall绘图",
          path: "/dallDraw",
          icon: "dall",
        },
        {
          name: "SD绘图",
          path: "/sdDraw",
          icon: "sd",
        },
        {
          name: "作图历史",
          path: "/draw-history",
          icon: "history",
        },
        {
          name: "AI工具",
          path: "/aiTool",
          icon: "tool",
        },
      ],
      defaultActive: "/talk",
    };
  },
  mounted() {
    this.defaultActive = this.$route.path;
  },
  methods: {
    change({ path }) {
      this.$router.replace(path);
      this.defaultActive = path;
    },
  },
};
</script>
<style lang="less" scoped>
.layout_sidebar {
  height: 100%;
  width: 100px;
  border-right: 1px solid #ebebeb;
  box-sizing: border-box;
  padding: 10px 0;
  .submenu {
    color: #6f747b;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 5px auto 10px;
    cursor: pointer;
    border-radius: 50%; /* 初始为圆形 */
    transition: border-radius 0.3s ease; /* 动画效果 */

    &.active {
      background-color: #eceff1;
      border-radius: 10px; /* hover时变为方形 */
      .icon_box {
        fill: #7a46fc;
      }

      color: #7a46fc;
    }
    &:hover {
      background-color: #eceff1;
      border-radius: 10px; /* hover时变为方形 */
    }

    .icon_box {
      font-size: 20px;
      fill: #000;
    }

    .name {
      font-size: 14px;
    }
  }
}
</style>
