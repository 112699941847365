import request from "@/utils/httpRequest";

export default {
  list: function () {
    return request({
      url: "/front/api/apiSetting/list",
      method: "get",
    });
  },
};
