<template>
  <div class="navbar">
    <span
      style="
        display: inline-block;
        width: 204px;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        float: left;
      "
    >
      <router-link to="/document"> JeePlus 文档 </router-link>
    </span>
    <div v-if="currentUser.id" class="user-info">
      <div class="username">
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            <el-avatar
              :size="28"
              :src="currentUser.avatarUrl"
              style="position: relative; top: 7px; right: 5px"
            ></el-avatar>
            <span class="name">{{ currentUser.nickname }}</span> |
            <span class="title"> {{ currentUser.title }}</span>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="userInfo">账号信息</el-dropdown-item>
              <el-dropdown-item command="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div class="user-info" v-else>
      <div class="username">
        <router-link tag="li" :to="{ path: '/login' }">
          <span>登录</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from "@/service/token";
import loginService from "@/service/api/loginService";
import { SelfElMessage } from "@/utils/utils";
export default {
  data() {
    return {};
  },
  computed: {
    currentUser() {
      return this.$store.getters.getUser;
    },
    currentToken() {
      return getToken();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      if (getToken()) {
        try {
          this.$store.dispatch("getUserInfo").then(() => {
            console.log("login success");
          });
        } catch (e) {
          console.log(e);
        }
      }
    },
    changeConsole(value) {
      this.console = value;
      this.$router.push(value);
    },
    backToHome() {
      window.location.href = "/";
    },
    loginOut() {
      this.$confirm("确定要退出登录吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          loginService.logout().then(() => {
            this.$store.dispatch("loginOut");
            this.$router.push({ path: "/home" });
            SelfElMessage({ type: "success", message: "退出登录成功" });
          });
        })
        .catch(() => {
          console.log("error!!");
        });
    },
    async handleCommand(command) {
      if (command === "logout") {
        this.loginOut();
      }
      if (command === "userInfo") {
        this.$router.push("/userCenter/userInfo");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.navbar {
  position: relative;
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #e5e7e9;
  .logo {
    position: absolute;
    left: 40.8px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .console {
    margin-left: 280px;
  }
  .user-info {
    position: absolute;
    top: 0;
    right: 40px;
    font-size: 14px;
    color: #111;
    .help {
      margin-right: 28px;
      &:hover {
        color: #7b46fcd6;
        cursor: pointer;
      }
    }
    img {
      margin: 0 15px;
      transform: translateY(3px);
    }
    .backup {
      margin-right: 0;
    }
    .username {
      display: inline-block;
      cursor: pointer;
      .level {
        position: absolute;
        left: 0px;
        bottom: -3px;
      }
      .name {
        margin-left: 8px;
      }
    }
  }
}
.el-select-dropdown__item {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  color: #111;
  cursor: default;
  width: 160px;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0);
  }
  &.selected {
    display: none;
    font-weight: 400;
    color: #111;
  }
  span {
    &:last-child {
      color: #7b46fcd6;
    }
  }
}
.el-select {
  ::v-deep .el-icon-arrow-up {
    font-family: "iconfont" !important;
    transform: none !important;
    line-height: 25px;
  }
  ::v-deep .el-icon-arrow-up:before {
    content: "\e68e";
    color: #666;
    font-size: 12px;
  }
  ::v-deep .el-select__caret {
    &.is-reverse {
      transform: none;
    }
  }
  ::v-deep.el-input .el-select__caret {
    background-color: seashell;
  }
}
</style>
<style lang="less">
.navbar {
  .console .el-input__inner {
    border: none;
    border-radius: 2px;
    width: 112px;
    padding-left: 10px;
    padding-right: 30px;
    height: 26px;
    line-height: 26px;
    background: #f0f2f5;
  }
  .search {
    margin-left: 263px;
    width: 265px;
    .el-input__inner {
      border: none;
      border-radius: 2px;
      height: 32px;
      line-height: 32px;
      background: #f0f2f5;
    }
  }
  .title {
    color: #fff;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 10px;
    background-image: linear-gradient(90deg, #fe8800 0%, #ff5500 100%);
  }
}
</style>
